import type { OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import type { Subscription } from 'rxjs';
import { fromEvent } from 'rxjs';
import type { UserWayEvent } from './accessibility.interface';

@Injectable({ providedIn: 'root' })
export class Accessibility implements OnDestroy {
  private subscription?: Subscription;

  // Open UserWay external widget - https://userway.org
  openUserWayWidget(): void {
    const existingScript = document.getElementById('userWay-widget');
    if (!existingScript) {
      const script = document.createElement('script');
      script.setAttribute('id', 'userWay-widget');
      script.setAttribute('data-account', 'sGgJ6WxeqA');
      script.setAttribute('src', 'https://cdn.userway.org/widget.js');
      const firstScript = document.querySelector('script');
      document.head.insertBefore(script, firstScript);

      /* Open UserWay accessibility menu on init */

      this.subscription = fromEvent(
        document,
        'userway:init_completed',
      ).subscribe(e => {
        const event = e as UserWayEvent;
        const userwayInstance = event.detail.userWayInstance;
        if (userwayInstance) {
          userwayInstance.widgetOpen();
        } else {
          console.error('No UserWay instance found in payload');
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
