import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Accessibility } from '@freelancer/accessibility';

@Component({
  selector: 'webapp-compat-accessibility',
  template: ` <ng-container></ng-container> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompatAccessibilityComponent implements OnInit {
  constructor(private accessibility: Accessibility) {}

  ngOnInit(): void {
    const w = window as any;

    w.webappCompatAccessibility = {
      getAccessibilityWidget: () => {
        this.accessibility.openUserWayWidget();
      },
    };
  }
}
